img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.App {
  text-align: center;
  overflow: hidden;
}

.App::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.App-header {
  height: 70px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.App-header-left-box {
  height: 100%;
  width: 155px;
  border: 2px solid #000;
  background-color: #7fd0c5;
  position: relative;
  height: 100%;
}

.App-header-right-box {
  height: 100%;
  width: 155px;
  border: 2px solid #000;
  background-color: #ffba57;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  height: 100%;
}

.App-header-right-box img {
  width: 40px;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.App-header-left-button {
  width: 88px;
  height: auto;
  background-color: #ffffff;
  border: 2px solid #000;
  border-radius: 32px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-header-left-button p {
  font-size: 18px;
  color: #000;
  line-height: 0.6rem;
}

.App-content {
  width: 100%;
  height: 100vh;
  background-image: url("./Assets/background.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  background-position: center;
}

.App-toy-product {
  width: auto;
  height: 90vh;
}

.App-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #000;
  border-radius: 120px;
  width: 362px;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 15px black;
}

.App-modal-content-gesture .App-button {
  box-shadow: none;
}

.App-button p {
  color: #000;
  font-weight: bold;
  font-size: 25px;
  line-height: 1rem;
}

.App-nft-guarantee {
  position: absolute;
  top: 20%;
  left: 19%;
  max-width: 333px;
  max-height: 94px;
}

.App-one-for-one {
  position: absolute;
  top: 50%;
  left: 26%;
  max-width: 117px;
  max-height: 83px;
}

.App-million-doge {
  position: absolute;
  top: 26.5%;
  right: 26%;
  max-width: 205px;
  max-height: 91px;
}

.App-nft-guarantee img {
  width: 100%;
  height: auto;
}

.App-one-for-one img {
  width: 100%;
  height: auto;
}

.App-million-doge img {
  width: 100%;
  height: auto;
}

.App-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
  /*pointer-events: unset;*/
}

.App-modal {
  position: absolute;
  top: calc(50vh - 40px);
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  margin-top: 40px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  padding: 0;
  border: none;
  border-radius: 4px;
  -webkit-overflow-scrolling: touch;
  background: transparent;
  overflow: hidden;
  padding-bottom: 0;
  max-height: calc(90vh - 75px);
  outline: none;
  /*z-index: 90;*/
}

.App-modal-close {
  margin-right: -20px;
  font-size: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: black;
}

.App-modal-content {
  background-color: #fff;
  max-width: 700px;
  height: auto;
  border: 5px solid #000000;
  padding: 20px 40px 40px 40px;
  margin: 0 15px;
}

.App-modal-content-tutorials {
  overflow-y: scroll;
  scrollbar-width: none;
}

.App-modal-content-tutorials::-webkit-scrollbar {
  display: none;
}

.App-modal-content-tutorials > .App-modal-content-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
}

.App-modal-content-tutorials > .App-modal-content-description {
  font-size: 16px;
  line-height: 23px;
  padding-top: 30px;
}

.App-modal-content-tutorials > .App-modal-content-description-extra {
  font-size: 14px;
  line-height: 19px;
  padding-top: 30px;
}

.App-modal-content-tutorials > .App-modal-content-padding {
  padding-top: 30px;
}

.App-modal-content-gesture-change {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.App-modal-content-gesture {
  height: 90vh;
  text-align: center;
  margin: 0 10vh;
}

.App-modal-gesture-container {
  position: relative;
  top: 24vh;
  right: 0;
  left: 0;
}

.App-hidden-content {
  visibility: hidden;
}

/* For mobile phones: */
/* This help while user using website and scale it like mobile the background image isn't break */
@media (min-width: 451px) {
  .App-content {
    min-width: 1440px;
  }
}

@media only screen and (max-width: 768px) {
  .App-content {
    width: 330vw;
  }

  .App-nft-guarantee {
    position: relative;
    top: -44%;
    left: 3%;
    max-width: 333px;
    max-height: 94px;
  }

  .App-one-for-one {
    position: relative;
    top: -27%;
    left: 18%;
    max-width: 120px;
    max-height: 86px;
  }

  .App-million-doge {
    position: relative;
    top: -59%;
    left: 71%;
    max-width: 205px;
    max-height: 91px;
  }

  .App-toy-product {
    position: relative;
    width: auto;
    height: 65vh;
    right: 0.5%;
    bottom: -20vh;
    /*bottom: calc(-5% - 10vh - 70px);*/
    /*bottom: -webkit-calc(-5% - 10vh - 70px);*/
  }

  .App-button {
    width: 70vw;
    bottom: 40px;
  }

  .App-modal-content-tutorials {
    height: 60vh;
  }

  .App-modal-content-tutorials > .App-modal-content-description {
    padding-top: 0;
  }

  .App-modal-content-tutorials > .App-modal-content-description-extra {
    padding-top: 0;
  }

  .App-modal-content-tutorials > .App-modal-content-padding {
    padding-top: 0;
  }

  .App-header-left-box {
    width: 30%;
    border-left-width: 0px;
    border-right-width: 1px;
  }

  .App-header-right-box {
    width: 70%;
    border-left-width: 1px;
    border-right-width: 0px;
    justify-content: flex-end;
    align-items: center;
  }

  .App-header-right-box img {
    padding-right: 20px;
  }

  .App-header-left-button {
    width: 70px;
  }
}

@media only screen and (max-width: 700px) {
  body {
    max-width: 100%;
    min-height: 100vh;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  html {
    min-height: -webkit-fill-available;
  }
}

/* For iOS devices only */
/* Safari (and any browser on iOS really, since they are forced to use Safari's WebKit engine) does not */
/* report the correct viewport height when the toolbar on the bottom is visible. We'll have to compensate */
/* for that. */

/* CSS Hax */
/* Only Safari on iOS supports webkit-touch-callout */
/* Should other browsers support this function in the future for some reason, go to this page to find another Safari exclusive function: */
/* https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariCSSRef/Articles/StandardCSSProperties.html */

@supports (-webkit-touch-callout: none) {
  .App-modal {
    top: calc(43vh - 40px);
  }

  .App-toy-product {
    bottom: -14vh;
  }

  .App-button {
    bottom: 25px;
  }

  .App-modal-content-tutorials {
    height: 65vh;
  }
}

/* Responsive galore! */

@media only screen and (max-width: 428px) {
  .App-nft-guarantee {
    left: 6%;
  }
}

@media only screen and (max-width: 414px) {
  .App-nft-guarantee {
    left: 5%;
  }
}

@media only screen and (max-width: 390px) {
  .App-nft-guarantee {
    left: 3%;
  }
}

@media only screen and (max-width: 375px) {
  .App-button p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .App-nft-guarantee {
    left: 3%;
  }
}

@media only screen and (max-width: 324px) {
  .App-nft-guarantee {
    left: 0%;
  }
}