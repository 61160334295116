@font-face {
  font-family: "ComicSansMS3";
  src: local("ComicSansMS3") ,url('./Fonts//ComicSansMS3.ttf');
}

body {
  margin: 0 auto;
  font-family: "ComicSansMS3";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}